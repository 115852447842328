import { FormattedMessage, useIntl } from "react-intl";
import '../GeneralFooter.less';

import partnership1 from '../../../../assets/images/partnership.png';
import partnership2 from '../../../../assets/images/casinoguru_logo.svg';
import partnership3 from '../../../../assets/images/online-casino-singapore.png';
import partnership5 from '../../../../assets/images/online-casino-sg-logo.png';
import partnership4 from '../../../../assets/images/logo_bt10-1.svg'
import partnership6 from '../../../../assets/images/logo-bestcasinosg.png';

import { useLayout } from "../../../../hooks/layout/LayoutContext";

const FooterPartnership = () => {
    const { layoutState } = useLayout();

    const onPartnershipClick = (type: string) => {
        switch (type) {
            case 'partnership':
                window.open(`https://casinosanalyzer.com/online-casinos/ib8.asia`);
                break;
            case 'casinoguru':
                window.open(`https://casino.guru/ib8-casino-review`);
                break;
            case 'online-casino-sg':
                window.open(`https://www.onlinecasino-sg.com.sg/ib8/`);
                break;
            case 'oc':
                window.open(`https://onlinecasino.com.sg/ib8-casino-review`);
                break;
            case 'bettingtop10':
                window.open(`https://www.bettingtop10.com/ib8/`);
                break;
            case 'best-online-casino-sg':
                window.open(`https://bestcasino.com.sg/review/ib8/`);
                break;
        }
    }

    return (
        <div className='footer-second-row'>
            <div className='footer-provider-title'><FormattedMessage id='FOOTER_PARTNERSHIP' /></div>
            <div className='footer-provider-items-list' style={{ marginBottom: "0px" }}>
                {layoutState.currency === 'SGD' && <>
                    <div className='footer-provider-items'>
                        <img src={partnership3} alt="ocs" className="payment-method-icon clickable"
                            onClick={() => { onPartnershipClick('online-casino-sg') }}
                        />
                    </div>
                    <div className='footer-provider-items'>
                        <img src={partnership6} alt="bocs" className="payment-method-icon clickable"
                            onClick={() => { onPartnershipClick('best-online-casino-sg') }}
                        />
                    </div>
                </>
                }
                <div className='footer-provider-items'>
                    <img src={partnership1} alt="ca" className="payment-method-icon clickable"
                        onClick={() => { onPartnershipClick('partnership') }}
                    />
                </div>
                <div className='footer-provider-items'>
                    <img style={{ width: "100%" }} src={partnership2} alt="cg" className="payment-method-icon clickable"
                        onClick={() => { onPartnershipClick('casinoguru') }}
                    />
                </div>
                {layoutState.currency === 'SGD' && <>
                    <div className='footer-provider-items'>
                        <img src={partnership5} alt="ocsg" className="payment-method-icon clickable"
                            onClick={() => { onPartnershipClick('oc') }}
                        />
                    </div>
                </>
                }
                {layoutState.currency === 'THB' && <>
                    <div className='footer-provider-items'>
                        <img style={{ width: "60%" }} src={partnership4} alt="cg" className="payment-method-icon clickable"
                            onClick={() => { onPartnershipClick('bettingtop10') }}
                        />
                    </div>
                </>
                }
            </div>
            <div >
                <b><div>Read what our partners say about <a href="https://onlinecasino-sg.com.sg/ib8/">IB8</a>and <a href="https://bestcasino.com.sg/review/ib8/">IB8 Casino</a>.</div></b>
            </div>
        </div>
    )
}

export default FooterPartnership;